import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

interface ItemProps {
  title: string
}

export interface Props {
  image?: ImageProps
  items: ItemProps[]
  title?: string
}

export const BlockServices = memo(function BlockServices({
  image,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Background />
        <Left>{image ? <StyledImage {...image} /> : null}</Left>
        <Right>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          <FadeInUp>
            <Items>
              {items.map((item, index) => (
                <ListItem key={index}>{item.title}</ListItem>
              ))}
            </Items>
          </FadeInUp>
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;
  padding-top: 9.375rem;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
    padding-top: 5rem;
  }

  @media (max-width: 992px) {
    padding-bottom: 5rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Background = styled.div`
  position: absolute;
  top: -9.375rem;
  left: 8.9375rem;
  width: 100%;
  height: 100%;
  min-height: 43.75rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  z-index: 0;

  @media (max-width: 1600px) {
    left: 8.9375vw;
  }

  @media (max-width: 1199px) {
    top: -5rem;
    right: -1.25rem;
    width: calc(100% + 1.25rem - 8.9375vw);
    min-height: unset;
  }

  @media (max-width: 992px) {
    right: unset;
    left: -1.25rem;
    width: calc(100% + 1.25rem - 0.9375rem);
    height: calc(100% + 10rem);
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Right = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: 5rem;
  margin-left: 8.9375rem;
  z-index: 1;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1440px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-left: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1199px) {
    margin-left: -1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-right: -1.25rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Title = styled.h2`
  ${styles.h2}

  @media (max-width: 992px) {
    margin-right: 2.1875rem;
  }
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 28.125rem;
  margin-top: 2.5rem;
  margin-bottom: -0.95rem;
  margin-left: -1.25rem;

  @media (max-width: 1199px) {
    margin-top: 1.375rem;
  }

  @media (max-width: 992px) {
    justify-content: center;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const ListItem = styled.div`
  width: calc(50% - 1.25rem);
  margin-bottom: 0.95rem;
  margin-left: 1.25rem;
  padding-top: 1rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.label};
  font-weight: 600;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 992px) {
    max-width: 12.5rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`
