import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { styles } from 'app/theme'
import React, { memo } from 'react'

interface ItemProps {
  icon?: string
  pax?: string
  title: string
}

export interface Props {
  items: ItemProps[]
  label?: string
  title?: string
}

export const Pax = memo(function Pax({ items, label, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <Items>
            {items.map((item, index) => (
              <Item key={index}>
                {item.pax ? <Number>{item.pax}</Number> : null}
                {item.icon ? (
                  <Icon
                    src={item.icon}
                    alt={item.title}
                    width={50}
                    height={30}
                  />
                ) : null}
                <Name>{item.title}</Name>
              </Item>
            ))}
          </Items>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: -25.625rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: -23.125rem 1.25rem 0;
  }

  @media (max-width: 992px) {
    margin: -10rem 0 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
  padding: 15rem 8.9375rem 8.75rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  transform: translateX(-8.9375rem);
  z-index: -1;

  @media (max-width: 1600px) {
    padding-right: 8.9375vw;
    transform: translateX(-8.9375vw);
  }

  @media (max-width: 1199px) {
    padding-top: 25.625rem;
    padding-bottom: 5rem;
  }

  @media (max-width: 992px) {
    align-items: center;
    padding: 12.5rem 1.25rem 3.125rem;
    transform: unset;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Label = styled.div`
  ${styles.label}
  @media (min-width: 1200px) {
    margin-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  ${styles.h2}
  width: 43.75vw;
  max-width: 43.75rem;

  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 43.75vw;
  max-width: 43.75rem;
  margin-top: 2.5rem;
  margin-bottom: -1.5625rem;
  margin-left: -1.875rem;

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
    margin-top: 1.875rem;
  }

  @media (max-width: 992px) {
    justify-content: center;
    max-width: 34.375rem;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(20% - 1.875rem);
  height: 7.5rem;
  margin-bottom: 1.5625rem;
  margin-left: 1.875rem;

  @media (max-width: 992px) {
    width: calc(33.33% - 1.875rem);
  }
`

const Number = styled.div`
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1.5625rem;
  text-align: center;
`

const Icon = styled.img`
  margin-bottom: 1.0625rem;
`

const Name = styled.div`
  min-width: 4.375rem;
  font-family: ${({ theme }) => theme.fontFamily.label};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: bold;
  font-size: 0.5625rem;
  letter-spacing: 0.1em;
  line-height: 0.8125rem;
  text-align: center;
  text-transform: uppercase;
`
